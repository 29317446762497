export const useMainStore = defineStore('main', {
    state: () => ({
        newsLetterSubscribeIsVisible: false,
        menuOpen: false
    }),
    actions: {
        toggleNewsletterSubscribe(bool) {
            this.newsLetterSubscribeIsVisible = bool;
        },
        toggleMenuOpen(bool) {
            this.menuOpen = bool;
        }
    },
});
