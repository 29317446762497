{
    "error": {
        "404-title": "De pagina die je zoekt bestaat niet (meer).",
        "404-subtitle": "Misschien ben je hiernaar opzoek:",
        "500-title": "Er ging iets mis",
        "500-subtitle": "Misschien ben je hiernaar opzoek:",
        "request-subsidy": {
            "label": "Subsidie aanvragen",
            "link": "/subsidies"
        },
        "deadlines": {
            "label": "Deadlines",
            "link": "/deadlines"
        },
        "contact": {
            "label": "Contact",
            "link": "/contact"
        },
        "about": {
            "label": "Over het Filmfonds",
            "link": "/over-het-fonds"
        }
    },
    "newsletter": {
        "title": "Ontvang maandelijks het laatste nieuws, updates over subsidies en aankomende deadlines in je inbox.",
        "email_field": "E-mailadres",
        "email_input": "E-mailadres",
        "button": "Inschrijven",
        "placeholder": "Jose.Manggio{'@'}yahoo",
        "is_success": "Bedankt voor je inschrijving!",
        "is_loading": "Het formulier wordt nu verwerkt.",
        "has_error": "Error er ging iets fout."
    },
    "menu": {
        "open-menu-sr": "Open menu",
        "close-menu-sr": "Sluit menu",
        "open-menu": "Menu",
        "close-menu": "Sluiten",
        "login": "Inloggen"
    },
    "skiplinks": {
        "content": "Spring naar inhoud",
        "footer": "Spring naar footer"
    },
    "a11y": {
        "return-to": "Terug naar",
        "until": "tot",
        "paginator": {
            "page_prefix_sr": "Pagina: ",
            "previous_sr": "Vorige pagina",
            "next_sr": "Volgende pagina"
        }
    },
    "search": {
        "search": "Zoeken",
        "placeholder": "Zoeken",
        "loading": "Resultaten worden geladen...",
        "clear": "Zoekveld leegmaken",
        "resultsFound": "Geen resultaten gevonden | 1 resultaat voor \"{query}\" | {count} resultaten voor \"{query}\"",
        "entryNames": {
            "page_page_Entry": "Informatiepagina",
            "subsidies_informationPage_Entry": "Informatiepagina",
            "subsidies_subsidy_Entry": "Subsidie",
            "subsidiesOverview_subsidiesOverview_Entry": "Subsidieoverzicht",
            "posts_default_Entry": "Actueel",
            "postsOverview_postsOverview_Entry": "Actueel",
            "teamMembers_default_Entry": "Medewerker",
            "teamOverview_teamOverview_Entry": "Teamoverzicht",
            "eventOverview_eventOverview_Entry": "Agenda",
            "events_default_Entry": "Event",
            "periodsOverview_periodsOverview_Entry": "Deadlines"
        }
    },
    "teamOverview": {
        "backlink": {
            "label": "Over het fonds",
            "uri": "over-het-fonds"
        },
        "no_results": "Geen teamleden gevonden"
    },
    "subsidies": {
        "activeSubsidies": "Geen subsidies actief | 1 subsidie actief | {count} subsidies actief",
        "subsidy": "Subsidie",
        "backlink": "Subsidie aanvragen",
        "title_prefix": "Subsidies voor",
        "status": {
            "open": "Open",
            "open_until": "Open tot en met",
            "closed": "Gesloten",
            "closed_until": "Gesloten tot en met"
        },
        "filters": {
            "audience": {
                "label": "Voor wie",
                "placeholder": "Selecteer voor wie"
            },
            "phase": {
                "label": "Welke fase",
                "placeholder": "Selecteer een fase"
            }
        },
        "no_results": "Geen subsidies gevonden"
    },
    "post_cards": {
        "big_link": {
            "title": "Bekijk alle berichten",
            "uri": "actueel"
        }
    },
    "help_card": {
        "last_updated": "Geüpdate op"
    },
    "subsidy_hero": {
        "subsidy_tag": "Subsidie"
    },
    "filter": {
        "select": "Selecteer",
        "selected": "Geselecteerd",
        "unselect": "Verwijder",
        "search_filter_label": "Zoek op filters",
        "search_placeholder": "Zoek op filters",
        "accessibility": {
            "select": "Selecteer filter: ",
            "unselect": "Verwijder filter: "
        }
    },
    "actueel": {
        "filter": {
            "type": {
                "label": "Type",
                "placeholder": "Selecteer type bericht"
            }
        },
        "no_results": "Geen berichten gevonden"
    },
    "toegewezen_bijdragen": {
        "backlink": {
            "uri": "/subsidies",
            "label": "Subsidie aanvragen"
        },
        "filter": {
            "search_label": "Zoeken",
            "category": {
                "label": "Categorie",
                "placeholder": "Selecteer categorie"
            },
            "department": {
                "label": "Afdeling",
                "placeholder": "Selecteer een afdeling"
            },
            "sort": {
                "label": "Bijdragesoort",
                "placeholder": "Selecteer bijdragesoort"
            }
        },
        "table": {
            "project_title": "Titel project",
            "requestee": "Aanvrager",
            "director": "Regisseur",
            "screenwriter": "Scenarist",
            "category": "Categorie",
            "subsidy_type": "Bijdragesoort",
            "amount": "Bedrag",
            "date": "Datum"
        },
        "no_results": "Geen toegewezen bijdragen gevonden"
    },
    "deadlines": {
        "upcoming_deadlines": "Eerstvolgende deadlines"
    },
    "video": {
        "no_audio_desc": "Toegankelijke video bekijken",
        "audio_desc": "Originele video bekijken"
    },
    "team": {
        "more_info": "Meer informatie",
        "about": "over"
    }
}
