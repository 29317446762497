{
    "error": {
        "404-title": "The page you're looking for doesn't exist (anymore)",
        "404-subtitle": "Perhaps you're looking for:",
        "500-title": "Something went wrong",
        "500-subtitle": "Perhaps you're looking for:",
        "request-subsidy": {
            "label": "Request subsidy",
            "link": "/en/funding"
        },
        "deadlines": {
            "label": "Deadlines",
            "link": "/en/deadlines"
        },
        "contact": {
            "label": "Contact",
            "link": "/en/contact"
        },
        "about": {
            "label": "About the Film Fund",
            "link": "/en/about-film-fund"
        }
    },
    "newsletter": {
        "title": "Receive the latest news, updates on our funding options and our deadlines in your inbox",
        "email_field": "Email address",
        "email_input": "Email address",
        "button": "Sign up",
        "placeholder": "Jose.Manggio{'@'}yahoo",
        "is_success": "Thank you!",
        "is_loading": "The form is being processed",
        "has_error": "Error something went wrong."
    },
    "menu": {
        "open-menu-sr": "Open menu",
        "close-menu-sr": "Close menu",
        "open-menu": "Menu",
        "close-menu": "Close",
        "login": "Log in"
    },
    "skiplinks": {
        "content": "Jump to content",
        "footer": "Jump to footer"
    },
    "a11y": {
        "return-to": "Return to",
        "until": "until",
        "paginator": {
            "page_prefix_sr": "Page: ",
            "previous_sr": "Previous page",
            "next_sr": "Next page"
        }
    },
    "search": {
        "search": "Search",
        "placeholder": "Type something...",
        "loading": "Loading results...",
        "clear": "Clear search field",
        "resultsFound": "No results found | 1 result for \"{query}\" | {count} results for \"{query}\"",
        "entryNames": {
            "page_page_Entry": "Information Page",
            "subsidies_informationPage_Entry": "Information Page",
            "subsidies_subsidy_Entry": "Subsidy",
            "subsidiesOverview_subsidiesOverview_Entry": "Subsidy Overview",
            "posts_default_Entry": "News",
            "postsOverview_postsOverview_Entry": "News",
            "teamMembers_default_Entry": "Team Member",
            "teamOverview_teamOverview_Entry": "Team Overview",
            "eventOverview_eventOverview_Entry": "Agenda",
            "events_default_Entry": "Event",
            "periodsOverview_periodsOverview_Entry": "Deadlines"
        }
    },
    "teamOverview": {
        "backlink": {
            "label": "About us",
            "uri": "about-us"
        },
        "no_results": "No team members found"
    },
    "subsidies": {
        "activeSubsidies": "No active subsidies | 1 active subsidy | {count} active subsidies",
        "subsidy": "Subsidy",
        "backlink": "Apply for subsidy",
        "title_prefix": "Subsidies for",
        "status": {
            "open": "Open",
            "open_until": "Open until",
            "closed": "Closed",
            "closed_until": "Closed until"
        },
        "filters": {
            "audience": {
                "label": "For who",
                "placeholder": "Select for who"
            },
            "phase": {
                "label": "Phase",
                "placeholder": "Select a phase"
            }
        },
        "no_results": "No subsidies found"
    },
    "post_cards": {
        "big_link": {
            "title": "View all news articles",
            "uri": "updates"
        }
    },
    "help_card": {
        "last_updated": "Last updated on"
    },
    "subsidy_hero": {
        "subsidy_tag": "Subsidy"
    },
    "filter": {
        "select": "Select",
        "selected": "Selected",
        "unselect": "Remove",
        "search_filter_label": "Search for filters",
        "search_placeholder": "Search for filters",
        "accessibility": {
            "select": "Select: ",
            "unselect": "Remove filter: "
        }
    },
    "actueel": {
        "filter": {
            "type": {
                "label": "Type",
                "placeholder": "Select article type"
            }
        },
        "no_results": "No news found"
    },
    "toegewezen_bijdragen": {
        "backlink": {
            "uri": "/subsidies",
            "label": "Apply for a subsidy"
        },
        "filter": {
            "search_label": "Search",
            "category": {
                "label": "Category",
                "placeholder": "Select a category"
            },
            "department": {
                "label": "Department",
                "placeholder": "Select a department"
            },
            "sort": {
                "label": "",
                "placeholder": ""
            }
        },
        "table": {
            "project_title": "Project title",
            "requestee": "Applicant",
            "director": "Director",
            "screenwriter": "Script",
            "category": "Category",
            "subsidy_type": "Subsidy type",
            "amount": "Total grant",
            "date": "Date"
        },
        "no_results": "No results founds"
    },
    "deadlines": {
        "upcoming_deadlines": "Upcoming deadlines"
    },
    "video": {
        "no_audio_description": "View accessible video",
        "audio_desc": "View original video"
    },
    "team": {
        "more_info": "More information",
        "about": "about"
    }
}
